<div class="details-page-container"
  style="height: 75px !important;background-color: white;">
  <app-placeholder-loader class="ts-col-100" *ngIf="!isTestCaseFetchingCompleted"
    [isDetails]="true"></app-placeholder-loader>
  <div class="ts-col-100 d-flex-wrap" *ngIf="testCase">
    <div class="details-page-header">
      <app-td-overlay-menu-button 
        [versionId]="testCase?.workspaceVersionId"></app-td-overlay-menu-button>
      <!-- <a class="go-back-icon pl-10"
         [matTooltip]="'hint.message.common.back' | translate"
         [routerLink]="['/td', testCase?.workspaceVersionId, testCase?.testcaseRedirection]"></a> -->
      <div class="d-flex" style="justify-content: space-between;width: 100%;position: relative;top: -3px;">
        <div class="theme-details-header ml-15">
          <a class="title-name " [matTooltip]="testCase.name" [textContent]="testCase.name"
            style="color: black !important;font-size: 22px !important;"></a>

          <span [matMenuTriggerFor]="moreOptions" style="
          border: 1px solid #acaee5; /* Solid border with 10px thickness and gray color */
          border-radius: 50%;
          margin-left: 14px;
          text-align: center;
          cursor: pointer;
          width: 30px !important;
          height: 30px !important;
          align-items: center;
          display: flex;
          justify-content: center;
      "><mat-icon style="color: #6366f1;"> more_horiz </mat-icon></span>
        </div>
        <!-- <div style="margin-left: 500px;">
            <button class="theme-btn-primary"
            (click)="testPopup()"
            >testpop</button>
        </div> -->

        <mat-menu #moreOptions="matMenu">
          <button mat-menu-item *ngIf="!testCase?.deleted" (click)="openTestCaseClone()" style="color: #6366F1;"
            [matTooltip]="'hint.message.common.clone' | translate"><mat-icon
              style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">filter_none</mat-icon>Clone</button>
          <button mat-menu-item *ngIf="!testCase?.deleted" style="color: #6366F1;"
            [routerLink]="['/td', testCase.workspaceVersionId, testCase.isStepGroup? 'step_groups':'cases', testCaseId, 'edit']"
            [matTooltip]="'hint.message.common.edit' | translate"><mat-icon
              style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">border_color</mat-icon>Edit</button>
          <button mat-menu-item *ngIf="!testCase?.deleted" (click)="deleteTestCase()" style="color: #6366F1;"
            [matTooltip]="'hint.message.common.delete' | translate"><mat-icon
              style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">delete</mat-icon>Delete</button>
          <button mat-menu-item (click)="openDetails()" style="color: #6366F1;"
            [matTooltip]="'hint.message.common.more' | translate "><mat-icon
              style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">more_horiz</mat-icon>More</button>
          <!-- <button mat-menu-item (click)="reportBug()" class="icon-btn border-rds-2 ml-14"><img src="/assets/images/jira-software-blue.svg" width="40px"/></button> -->
          <button mat-menu-item (click)="reportBug()" style="color: #6366F1;"
            [matTooltip]="'hint.message.common.jira_bug_report' | translate "><mat-icon
              style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">bug_report</mat-icon>Jira</button>
        </mat-menu>

        <div class="details-page-buttons">
          <div style="margin-right: 15px;" class="position-relative">
            <button class="theme-btn-primary " (click)="Validate()" style="height: 36px;" *ngIf="!sharedDataService.isRunningValidate">
              Validate
            </button>

            <picture *ngIf="sharedDataService.isRunningValidate">
              <img src="/assets/process.gif" width="40px" alt="" style="position: absolute; right: 0px;top: -5px;">
            </picture>

          </div>
          <div *ngIf="version?.workspace?.isMobileNative && !testCase?.deleted">
            <button *ngIf="hasInspectorFeature()" [routerLink]="['/td', 'record', testCase.workspaceVersionId]"
              [queryParams]="{isRecord: true, isStepRecord: true, testCaseId: testCaseId}"
              class="theme-btn-clear-default  mr-10" [translate]="'elements.btn.record'"></button>
          </div>
          <button *ngIf="!checkExtensionInstall" (click)="openSidePanelAndListSteps()"
            class="theme-btn-primary nlp-record-default-button mr-10 position-relative py-6 text-nowrap">
            <div class="rounded-circle btn mr-5 p-4 my-2 result-status-0" style="background: white;"></div>
            <span>Record</span>
          </button>

          <!-- install_plugin -->
          <button *ngIf="checkExtensionInstall" class="border-rds-left-2 mr-15 py-7 pl-14 pr-14 btn-primary mr-0 fz-13" (click)="installPlugin()">
            <img style="height: 13px;" src="../../assets/color_icons/cqa-logo.svg"> &nbsp; <span
              [translate]="'Install Plugin'"></span>
          </button>
          <!-- <div class="mr-10">
            <button class="theme-btn-primary" *ngIf="cqaOrigin != 'dtest01'" (click)="manuallyRun()" style="height: 36px;">Run
              Manually</button>
          </div> -->
          <div>
            <button *ngIf="stepsLength && canShowRunResult && !canShowBulkActions && cqaOrigin !== 'dtest01'" style="height: 36px;"
              class="theme-btn-primary" [disabled]="IsExecuteBtn" (click)="OnRun()">
              <i class="fa-filled-circled-play pr-7"></i>
              <span [translate]="IsExecuteBtn ? 'test_plan.btn.executing' : 'test_plan.btn.run'"></span>
            </button>

            <button *ngIf="stepsLength && canShowRunResult && !canShowBulkActions && cqaOrigin === 'dtest01'"
              [matMenuTriggerFor]="runOptions" class="theme-btn-primary" [disabled]="IsExecuteBtn" style="padding: 9px;">
              <i class="fa-filled-circled-play pr-7"></i>
              <span [translate]="IsExecuteBtn ? 'test_plan.btn.executing' : 'test_plan.btn.run'"></span>
            </button>

            <mat-menu #runOptions="matMenu">
              <button mat-menu-item (click)="OnRun()" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  running_with_errors
                </mat-icon>
                Execution
              </button>
              <button mat-menu-item (click)="manuallyRun()" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  assignment
                </mat-icon>
                Run Manually
              </button>
              <button mat-menu-item (click)="reportsExecution('performance')" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  speed
                </mat-icon>
                Performance Testing
              </button>
              <button mat-menu-item (click)="reportsExecution('security')" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  verified_user
                </mat-icon>
                Security Testing
              </button>
              <button mat-menu-item (click)="reportsExecution('load')" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  cloud
                </mat-icon>
                Load Testing
              </button>
              <button mat-menu-item (click)="reportsExecution('accessibility')" style="color: #6366F1;">
                <mat-icon
                  style="color: #6366F1;font-size: 12px;padding: 4px;border: 1px solid #acaee5;border-radius: 19px;/* margin-left: 5px; */text-align: center;cursor: pointer;position: relative;/* top: 0px; */">
                  accessibility
                </mat-icon>
                Accessibility Testing
              </button>
            </mat-menu>
          </div>

          <app-test-case-detail-pagination *ngIf="!testCase?.deleted && false"
            [versionId]="testCase?.workspaceVersionId" [testCase]="testCase"></app-test-case-detail-pagination>
          <!-- <div *ngIf="version?.workspace?.isWeb"> -->
          <!-- <app-chrome-record-button *ngIf="isSidePanelOPened" (messageEvent)="receiveMessage($event)"></app-chrome-record-button> -->


          <!-- </div> -->
          <button *ngIf="testCase?.deleted" (click)="restore()" class="btn icon-btn border-rds-2 ml-14 text-success">
            <i class="fa-rev-brands mr-10"></i>
            <span [translate]="'btn.common.restore'"></span>
          </button>
          <button *ngIf="testCase?.deleted" (click)="fetchLinkedCases()"
            class="btn icon-btn border-rds-2 ml-14 text-danger">
            <i class="fa-trash mr-10"></i>
            <span [translate]="'btn.common.delete_permanently'"></span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="testCase?.isStepGroup">
      <div class="py-5 pl-10 pr-10 theme-yellow w-fit-content border-rds-3">
        <i class="fa-warning text-danger"></i>
        <span  [translate]="'testcase.details.step_group.edit_step.warning'" class="pl-10 fsm"></span>
      </div>
    </div>
  </div>
</div>
<div class="theme-details-scroll sm-h">
  <router-outlet></router-outlet>
</div>