<div
  #stepDetailsRef
  class="overflow-y-auto step-details-container action-step-details w-100"
  id="step-result-nav">

  <div
    *ngIf="!testStepResult"
    class="details-container ts-col-100">
    <div
      class="ml-30 mw-20 ts-col-auto"
      *ngFor="let two of [1,2]">
      <div class="text-line-loader"></div>
      <div class="text-line-loader mw-75"></div>
    </div>
  </div>
  
  <div *ngIf="testStepResult?.executedResult">
    <div class="fz-16 pb-10 theme-text rb-medium" [translate]="'test_step.results.result_AI'"></div>
    <div *ngIf="testStep?.actionName === 'ai_ask'"
      class="details-info pb-10" style="text-transform: capitalize;">
      {{testStepResult?.executedResult?.stepResult}}
    </div>
  </div>

  <div
    id="step_data"
    class="pb-40">
    <div class="d-flex pb-15">
    <div
      [translate]="'test_step.results.result_Analysis'"
      class="fz-16 theme-text ts-col-25 rb-medium" *ngIf="!testStepResult?.isManualExecution"></div>

      <!-- <div class="d-flex ts-col-auto fz-16  ">
      <i
        [class.fa-result-0]="testStepResult?.isPassed"
        [class.fa-result-1]="testStepResult?.isFailed"
        [class.fa-result-2]="testStepResult?.isAborted"
        [class.fa-result-3]="testStepResult?.isNotExecuted"
        [class.fa-result-5]="testStepResult?.isQueued"
        [class.fa-result-6]="testStepResult?.isStopped"
        class="pr-6"></i>
      <span
        [translate]="'execution.result.'+testStepResult?.result"
        [class.result-status-text-0]="testStepResult?.isPassed"
        [class.result-status-text-1]="testStepResult?.isFailed"
        [class.result-status-text-2]="testStepResult?.isAborted"
        [class.result-status-text-3]="testStepResult?.isNotExecuted"
        [class.result-status-text-5]="testStepResult?.isQueued"
        [class.result-status-text-6]="testStepResult?.isStopped"></span>
      </div> -->

      <div class="d-flex ts-col-auto fz-16 " style="font-weight: bold; font-size: 20px; text-align: center;    width: 25vw;">
        <div
          class="form-group ts-col-80" *ngIf="resultData?.isManualExecution">
          <button 
          (click)="onSelectChange('SUCCESS')" class="result_button py-7 pl-14 pr-14 fz-13 ng-star-inserted mr-4" style="background-color :#2ab95f !important" tabindex="0">
            <span>Passed</span>
          </button>
          <button
          (click)="onSelectChange('FAILURE')" class="result_button py-7 pl-14 pr-14  mr-4 fz-13 ng-star-inserted"  style="background-color :#f04b69 !important" tabindex="0">
            <span>Failed</span>
          </button>
          <button 
          (click)="onSelectChange('NOT_EXECUTED')" class="result_button py-7 pl-14 pr-14  fz-13 ng-star-inserted mr-2"  style="background-color :#ddb608 !important" tabindex="0">
            <span>Not Executed</span>
          </button>
          <!-- <mat-form-field class="mat-select-custom w-100" appearance="fill">
                 <mat-select class="custom-select" placeholder="Select Result" [(value)]="defaultValue"
                          (selectionChange)="onSelectChange($event)">
                <mat-option value="NOT_EXECUTED" >Not Executed</mat-option>
                <mat-option value="SUCCESS">Passed</mat-option>
                <mat-option value="FAILURE" >Failed</mat-option>
              </mat-select>
            </mat-form-field> -->
        </div>


        <ng-container *ngIf="testStepResult?.isManualExecution">
          <i
            [class.fa-result-0]="testStepResult?.isPassed"
            [class.fa-result-1]="testStepResult?.isFailed"
            [class.fa-result-2]="testStepResult?.isAborted"
            [class.fa-result-3]="testStepResult?.isNotExecuted"
            [class.fa-result-5]="testStepResult?.isQueued"
            [class.fa-result-6]="testStepResult?.isStopped"
            class="pr-6"></i>
          <span
            [translate]="'execution.result.'+testStepResult?.result"
            [class.result-status-text-0]="testStepResult?.isPassed"
            [class.result-status-text-1]="testStepResult?.isFailed"
            [class.result-status-text-2]="testStepResult?.isAborted"
            [class.result-status-text-3]="testStepResult?.isNotExecuted"
            [class.result-status-text-5]="testStepResult?.isQueued"
            [class.result-status-text-6]="testStepResult?.isStopped"></span>
        </ng-container>
      </div>


    </div>
    <div class="d-flex ts-col-auto fz-16 "  *ngIf="testStepResult?.isManualExecution && testStepResult?.message">
      <p>Comment: {{testStepResult?.message || ''}}</p>
    </div>
    <div class="details-container" *ngIf="!testStepResult?.isManualExecution">
      <button mat-raised-button color="success" [class.spinner]="loading" [disabled]="loading"
              (click)="executionResult(testStepResult)">Analyze Error</button>
    </div>
    <div >
        <br>
        <a [innerHTML]="analyzeError"></a>
      </div>
    </div>
    <div>
      <div class="details-container">
        <div *ngIf="!testStepResult?.isManualExecution && (!testStepResult?.isRestStep &&
         testStepResult?.testDataDetails)" class="ts-col-100">
          <div class="details-items ts-col-100 mb-15"
               *ngFor="let testData of testStepResult?.testDataDetails| keyvalue; let index=index">
            <div class="ts-col-100 d-flex" *ngIf="testData?.value.testDataValue">
              <label class="details-title ts-col-25" [translate]="'test_step.results.test_data'"></label>
              <div class="details-info mt-0"
                   [textContent]="testData?.value?.testDataValue" *ngIf="!testData?.value?.testDataFunctionEntity"></div>
              <div class="details-info mt-0"
                   [textContent]="testData?.value?.testDataName" *ngIf="testData?.value?.testDataFunctionEntity"></div>
            </div>
            <div class="d-flex" *ngIf="testData?.value?.testDataFunctionEntity">
              <label class="details-title ts-col-25"></label>
              <div class="mt-10">
                <div class="ts-col-100" *ngIf="!isEmptyObject(testData?.value?.testDataFunctionEntity?.arguments)">
                  <span [translate]="'natural_text_action.custom_function.suggestion.input'" class="rb-medium"></span>
                  <div *ngFor="let args of testData?.value?.testDataFunctionEntity?.arguments | keyvalue; let index=index" class="pl-5 pt-5">
                    <!--                    <span [textContent]="index+1" class="rb-medium"></span>-->
                    <span [textContent]="args.key+ ' = '" class="pl-5" *ngIf="!args.key.startsWith('arg')"></span>
                    <span [textContent]="args.value" class="pl-5"></span>
                  </div>
                </div>

                <div class="mt-5">
                  <span [translate]="'natural_text_action.custom_function.suggestion.output'" class="rb-medium"></span>
                  <span class="pl-5" [textContent]="testData?.value?.testDataValue"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="details-items ts-col-100 md-15 mb-15 d-flex" *ngIf="!testStepResult?.isManualExecution &&
        testStepResult?.errorCode">
          <label class="details-title ts-col-25" [translate]="'test_step.results.error_code'"></label>
          <div class="details-info mt-0" [textContent]="testStepResult?.errorCode">
          </div>
        </div>
        <div
          class="details-items ts-col-100 mb-25"
          *ngIf="!testStepResult?.isManualExecution && !testStepResult?.isRestStep &&
          testStepResult?.stepDetails?.dataMap?.elementString && !testStepResult?.addonElements">
          <div class="ts-col-100 d-flex">
            <div
              (click)="testStepResult.isOpen= !testStepResult.isOpen"
              class="details-title ts-col-25">
              <i
                class="fz-12"
                [class.fa-arrow-down-filled]="testStepResult.isOpen"
                [class.pr-7]="testStepResult.isOpen"
                [class.fa-arrow-right-filled]="!testStepResult.isOpen">
              </i>
              <label class="" [translate]="'test_step.results.element'"></label>
            </div>

            <div class="details-info d-flex align-items-center flex-wrap mt-0">
              <div
                [matTooltip]="testStepResult?.isElementChanged ? '' : 'step_result.hint.edit_element' | translate"
                class="pointer"
                (click)="testStepResult?.isElementChanged ? '' :  openEditElement(testStepResult?.stepDetails?.dataMap?.elementString)">
            <span
              class="text-link rb-medium"
              [textContent]="testStepResult?.stepDetails?.dataMap?.elementString"></span>
              <i class="fa-pencil-on-paper px-4" *ngIf="!testStepResult.isElementChanged"></i>
              </div>
            </div>


        </div>
          <div *ngIf="testStepResult?.isOpen && getElementDetails()" class="ts-col-100">
            <div class="details-item ts-col-100  align-item-center">
              <span [translate]="'elements.name'" class="details-title ts-col-30 rb-medium"></span>
              <span class="details-info m-0 pl-5" [textContent]="elementName"></span>
            </div>
            <div class="details-item ts-col-100  align-item-center">
              <span [translate]="'common.value'" class="details-title ts-col-30 rb-medium"></span>
              <span class="details-info m-0 pl-5" [textContent]="elementValue"></span>
            </div>
            <div class="details-item align-item-center">
              <div [translate]="'element.details.type'" class=" details-title ts-col-30 rb-medium"></div>
              <div class="details-info m-0 pl-5" [textContent]="elementType"></div>
            </div>
          </div>

        <div class="ts-col-100" *ngIf="!testStepResult?.isRestStep &&  testStepResult?.addonElements">
          <div
            class="details-items ts-col-100 mb-25" *ngFor="let addonElement of addonElementData; let index=index">
            <div class="ts-col-100 d-flex">
              <div
                (click)="addonElement.showInfo= !addonElement?.showInfo"
                class="details-title ts-col-25">
                <i
                  class="fz-12"
                  [class.fa-arrow-down-filled]="addonElement?.showInfo"
                  [class.pr-7]="addonElement?.showInfo"
                  [class.fa-arrow-right-filled]="!addonElement?.showInfo">
                </i>
                <label class="" [translate]="'test_step.results.element'"></label>
              </div>
              <div class="details-info d-flex align-items-center flex-wrap mt-0">
                <div
                  [matTooltip]="addonElement?.isElementChanged ? '' : 'step_result.hint.edit_element' | translate"
                  class="pointer"
                  (click)="addonElement?.isElementChanged ? '' : openEditElement(addonElement.name, true)">
                <span
                  class="text-link rb-medium"
                  [textContent]="addonElement.name"></span>
                  <i class="fa-pencil-on-paper px-4" *ngIf="!addonElement?.isElementChanged"></i>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="ShowFixElementCheck"
        class="d-flex justify-content-between">
        <div class="step-container">
          <div
            class="align-items-center d-flex p-0
         text-t-secondary pointer text-nowrap step-detail-p"
            (click)="testStepResult?.isElementChanged ? '' : openEditElement(testStepResult?.stepDetails?.dataMap?.elementString,true)">
            <i class="fa-config-fix fz-18"></i>
            <div
              [translate]="'step_result.fix_element'"
              class="rb-medium text-primary fz-14 pl-10">
            </div>
            <i class="fa-help fz-14 ml-10"></i>
          </div>
        </div>
      </div>
    </div>
        <div class="details-items ts-col-100 mb-15 d-flex" *ngIf="testStepResult?.stepDetails?.dataMap?.attribute">
          <label class="details-title ts-col-25" [translate]="'test_step.results.attribute'"></label>
          <div class="details-info mt-0"
               [textContent]="testStepResult?.stepDetails?.dataMap?.attribute">
          </div>
        </div>
<!--        <div class="details-items ts-col-100 mt-10">-->
<!--          <label class="rb-medium"-->
<!--                 [translate]="testStepResult?.isPassed || testStepResult?.isQueued ? 'message.common.msg' : 'step_result.error_message'"></label>-->
<!--          <div class="details-info rb-regular-i-d line-height-large"-->
<!--               [innerHTML]="testStepResult?.message"></div>-->
<!--          <div-->
<!--            class="note-info p-10 mt-10"-->
<!--            *ngIf="canShowFrameInfo">-->
<!--            <i class="fa-idea pr-2 result-status-text-2 fz-16"></i>-->
<!--            <span [innerHTML]="'step_result.error_with_frame' | translate"></span>-->
<!--          </div>-->
<!--        </div>-->
  </div>

    <div *ngIf="testStepResult?.executedResult?.autoHealedSteps">
      <div [translate]="'step_result.auto_healing'" class="fz-16 pb-10 theme-text rb-medium"></div>
      <div *ngFor="let displayAutohealdSteps of testStepResult?.executedResult?.autoHealedSteps">
        <div class="details-info rb-regular-i-d line-height-large">
          <span><b>After Locator :</b></span> {{displayAutohealdSteps.afterLocator}}
        </div>
        <div class="details-info rb-regular-i-d line-height-large">
          <span><b>Before Locator :</b></span> {{displayAutohealdSteps.beforeLocator}}
        </div>
        <div class="details-info rb-regular-i-d line-height-large">
          <span><b>IsAutoHealed :</b></span> {{displayAutohealdSteps.isAutoHealed}}
        </div>
        <div class="details-info rb-regular-i-d line-height-large">
          <span><b>StepIndex :</b></span> {{displayAutohealdSteps.stepIndex}}
        </div>
      </div>
    </div>

      <div class="" id="screenShort" >
        <div *ngIf="testStepResult?.screenshotName">
          <div [translate]="'step_result.screen_short_original'" class="fz-16 pb-10 theme-text rb-medium"></div>
          <div
            *ngIf="testStepResult?.screenshotName "
            class="theme-border theme-screen-short" [class.mobile-screen-short]="this.version?.workspace?.isMobile">
            <div class="action-section">
              <a
                target="_self"
                [href]="testStepResult?.screenshotName"
                [matTooltip]="'hint.message.common.download' | translate"
                class="fa-download-thin btn icon-btn"
                [download]="testStepResult?.screenshotName"></a>
              <i
                (click)="openScreenShort()"
                [matTooltip]="'hint.message.common.extend' | translate"
                class="fa-expand-alt btn icon-btn ml-14"></i>
            </div>
            <img
              class="screen-short"
              [src]="testStepResult?.screenshotName"
              (error)="setBrokenImage()">
          </div>
        </div>
        <!-- <div *ngIf="!testStepResult?.screenshotName">
          <a>
            <span class="text-center"
                  [innerHTML]="'step_result.not_capture.hasImageDifference' | translate"></span>
          </a>
        </div> -->
       <!-- <div
         class="theme-border theme-screen-short d-flex justify-content-center pt-30 rb-medium text-t-highlight"
         *ngIf="!testStepResult?.hasImageDifference">
           <span
             class="text-center px-20"
             [innerHTML]="'step_result.not_capture.hasImageDifference' | translate"></span>
       </div> -->
      
        <div *ngIf="testStepResult?.imageDifferenceUrl ">
          <div [translate]="'step_result.screen_short_visual_regression'" class="fz-16 pb-10 theme-text rb-medium"></div>

        <div
            *ngIf="testStepResult?.imageDifferenceUrl "
            class="theme-border theme-screen-short" [class.mobile-screen-short]="this.version?.workspace?.isMobile">
            <div class="action-section">
              <a
                target="_self"
                [href]="testStepResult?.imageDifferenceUrl"
                [matTooltip]="'hint.message.common.download' | translate"
                class="fa-download-thin btn icon-btn"
                [download]="testStepResult?.imageDifferenceUrl"></a>
              <i
                (click)="openScreenShortVR()"
                [matTooltip]="'hint.message.common.extend' | translate"
                class="fa-expand-alt btn icon-btn ml-14"></i>
            </div>
            <img
              class="screen-short"
              [src]="testStepResult?.imageDifferenceUrl"
              (error)="setBrokenImage()">
          </div>
        </div>
      </div>

      <!-- Recording video link ADD START -->
      <!-- <div>
        <div [translate]="'step_result.screen_short'" class="fz-16 pb-10 theme-text rb-medium"></div>
        <iframe width="420" height="210" src="https://www.youtube.com/embed/jQFrSty46Ec?autoplay=1&mute=1"></iframe>
      </div> -->
      <!-- Recording video link ADD END -->

      <div class="pt-30"  *ngIf="!testStepResult?.isManualExecution">
        <div
          (click)="toggleStepDetails()"
          class="fz-16 pt-10 theme-text pointer">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isShowStepDetails"
            [class.pr-7]="isShowStepDetails"
            [class.fa-arrow-right-filled]="!isShowStepDetails">
          </i>
          <span class="rb-medium" [translate]="'step_result.step.details.title'"></span>
        </div>
        <div *ngIf="isShowStepDetails">
            <div
                  id="metaData">
              <div class="details-container value-md md">
                <div class="details-items ts-col-30">
                  <label class="details-title" [translate]="'step_result.max.waitTime'"></label>
                  <div class="details-info"
                       [textContent]="'step_result.waitTime' | translate: {waitTime: testStepResult?.waitTime || 0}">
                  </div>
                </div>
                <div class="details-items ts-col-30">
                  <label class="details-title" [translate]="'test_step.results.prerequisite'"></label>
                  <div>
                    <a
                      class="details-info"
                      *ngIf="testStepResult?.metadata?.preRequisite || preRequestStep"
                      [routerLink]="['/td','test_case_results', testStepResult.testCaseResultId, 'step_results', preRequestStep?.id]"
                      [textContent]="'#' + preRequisiteStep">
                    </a>
                  </div>
                  <div
                    class="details-info"
                    *ngIf="!testStepResult?.metadata?.preRequisite"
                    [textContent]="'-'">
                  </div>
                </div>
                <div class="details-items ts-col-50">
                  <label class="details-title" [translate]="'test_step.priority.MAJOR_STOP'"></label>
                  <div
                    class="details-info"
                    [translate]="testStepResult?.stepDetails?.isMajor ? 'common.yes': 'common.no'">
                  </div>
                </div>
                <div class="details-items ts-col-50">
                  <label class="details-title" [translate]="'test_step.details.ignore_step_result'"></label>
                  <div
                    class="details-info"
                    [translate]="testStepResult?.stepDetails?.ignoreStepResult ? 'common.yes': 'common.no'">
                  </div>
                </div>
                <div class="details-items ts-col-100">
                  <label class="details-title" [translate]="'test_step.results.visual_enabled_label'"></label>
                  <div
                    class="details-info"
                    [translate]="testStepResult?.visualEnabled? 'common.yes': 'common.no'">
                  </div>
                </div>
                
              </div>
            </div>
        </div>
      </div>

      <!-- <div class="pt-30" *ngIf="htmlCodeGolden">
        <div class="fz-16 pt-10 theme-text pointer"
             (click)="toggleHtmlGoldenCode()">
          <i class="fz-12"
             [class.fa-arrow-down-filled]="htmlCodeGoldenToggle"
             [class.pr-7]="htmlCodeGoldenToggle"
             [class.fa-arrow-right-filled]="!htmlCodeGoldenToggle">
          </i>
          <span class="rb-medium" >Golden HTML code(Before Code) </span>
        </div>
        <div *ngIf="htmlCodeGoldenToggle"  class="pt-30" style=" ">
          <div style="background-color: #333; color: white; overflow: scroll; width: 100%;height: 70px; display: flex; align-items: center;">
            <code style="white-space: nowrap;" [innerHTML]="htmlCodeGolden">
            </code>
          </div>
        </div>
      </div> -->

      <div class="pt-30" *ngIf="htmlCodeDifference">
        <div class="fz-16 pt-10 theme-text pointer"
             (click)="toggleHtmlCodeDifference()">
          <i class="fz-12"
             [class.fa-arrow-down-filled]="htmlCodeDifferenceToggle"
             [class.pr-7]="htmlCodeDifferenceToggle"
             [class.fa-arrow-right-filled]="!htmlCodeDifferenceToggle">
          </i>
          <span class="rb-medium" >Difference HTML code(After Code) </span>
        </div>
        <div *ngIf="htmlCodeDifferenceToggle"  class="pt-30" style=" ">
          <div style="background-color: #333; color: white; overflow: scroll; width: 100%;height: 70px; display: flex; align-items: center;">
            <code style="white-space: nowrap;" [innerHTML]="htmlCodeDifference">
            </code>
          </div>
        </div>
      </div>



<!--      <div-->
<!--        id="metaData">-->
<!--        <div-->
<!--          [translate]="'step_result.step.details.title'"-->
<!--          class="fz-16 pb-20 theme-text rb-medium"></div>-->
<!--        <div class="details-container value-md md">-->
<!--          <div class="details-items ts-col-30">-->
<!--            <label class="details-title" [translate]="'step_result.max.waitTime'"></label>-->
<!--            <div class="details-info"-->
<!--                 [textContent]="'step_result.waitTime' | translate: {waitTime: testStepResult?.waitTime || 0}">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details-items ts-col-30">-->
<!--            <label class="details-title" [translate]="'test_step.results.prerequisite'"></label>-->
<!--            <div>-->
<!--              <a-->
<!--                class="details-info"-->
<!--                *ngIf="testStepResult?.metadata?.preRequisite || preRequestStep"-->
<!--                [routerLink]="['/td','test_case_results', testStepResult.testCaseResultId, 'step_results', preRequestStep?.id]"-->
<!--                [textContent]="'#' + preRequisiteStep">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div-->
<!--              class="details-info"-->
<!--              *ngIf="!testStepResult?.metadata?.preRequisite"-->
<!--              [textContent]="'-'">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details-items ts-col-50">-->
<!--            <label class="details-title" [translate]="'test_step.priority.MAJOR_STOP'"></label>-->
<!--            <div-->
<!--              class="details-info"-->
<!--              [translate]="testStepResult?.stepDetails?.isMajor ? 'common.yes': 'common.no'">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details-items ts-col-50">-->
<!--            <label class="details-title" [translate]="'test_step.details.ignore_step_result'"></label>-->
<!--            <div-->
<!--              class="details-info"-->
<!--              [translate]="testStepResult?.stepDetails?.ignoreStepResult ? 'common.yes': 'common.no'">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details-items ts-col-100">-->
<!--            <label class="details-title" [translate]="'test_step.results.visual_enabled_label'"></label>-->
<!--            <div-->
<!--              class="details-info"-->
<!--              [translate]="testStepResult?.visualEnabled? 'common.yes': 'common.no'">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div *ngIf="!testStepResult?.isRestStep && testStepResult?.stepDetails?.dataMap?.elementString && !testStepResult?.addonElements">
        <div class="fz-16 pt-10 theme-text pointer"
             (click)="toggleElementDetails()">
          <i class="fz-12"
             [class.fa-arrow-down-filled]="isShowElementDetails"
             [class.pr-7]="isShowElementDetails"
             [class.fa-arrow-right-filled]="!isShowElementDetails">
          </i>
          <span class="rb-medium" [textContent]="'Element details'"></span>
        </div>
        <ngx-json-viewer
          class="custom-json-view custom-stature"
          [json]="elementDetails" *ngIf="isShowElementDetails">
        </ngx-json-viewer>
      </div>
<!--      <div class="pt-40"-->
<!--        *ngIf="testStepResult && !(testStepResult.isStepGroup || testStepResult.isForLoop || testStepResult.isRestStep || testStepResult.stepGroup) || testStepResult?.template">-->
<!--        <div-->
<!--          (click)="toggleMetadata()"-->
<!--          class="fz-16 pb-2 theme-text pointer mat-body-2">-->
<!--          <i-->
<!--            class="fz-12"-->
<!--            [class.fa-arrow-down-filled]="isShowMetaData"-->
<!--            [class.pr-7]="isShowMetaData"-->
<!--            [class.fa-arrow-right-filled]="!isShowMetaData">-->
<!--          </i>-->
<!--          <span class="rb-medium" [translate]="'step_result.metadata'"></span>-->
<!--        </div>-->
<!--        <ngx-json-viewer-->
<!--          class="custom-json-view custom-stature"-->
<!--          [json]="testStepResult.metadata" *ngIf="isShowMetaData">-->
<!--        </ngx-json-viewer>-->
<!--      </div>-->

      <div class="pt-30" *ngIf="testStepResult?.executedResult?.messageLog &&
      testStepResult?.executedResult?.messageLog?.length">
        <div
          (click)="toggleMessageLog()"
          class="fz-16 pb-2 theme-text pointer mat-body-2">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isShowMessageLogs"
            [class.pr-7]="isShowMessageLogs"
            [class.fa-arrow-right-filled]="!isShowMessageLogs">
          </i>
          <span class="rb-medium" [translate]="'step_result.consoleLog'"></span>
        </div>
        <div *ngIf="isShowMessageLogs">
          <div class="log-item" *ngFor="let result of testStepResult?.executedResult?.messageLog"
          [ngClass]="{'err': result.type == 'ERROR'}">
            <span><b>Error:</b> {{ result.text }}</span> <br>
            <span><b>location:</b>  {{ result.location }}</span> <br>
          </div>
        </div>
      </div>

      <div class="pt-30" *ngIf="testStepResult?.executedResult?.networkLog &&
      testStepResult?.executedResult?.networkLog.length">
        <div
          (click)="toggleNetworkLogs()"
          class="fz-16 pb-2 theme-text pointer mat-body-2">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isShowNetworkLogs"
            [class.pr-7]="isShowNetworkLogs"
            [class.fa-arrow-right-filled]="!isShowNetworkLogs">
          </i>
          <span class="rb-medium" [translate]="'step_result.networkLog'"></span>
        </div>
        <div *ngIf="isShowNetworkLogs">
          <div class="log-item"  *ngFor="let result of testStepResult?.executedResult?.networkLog" [ngClass]="{'err': result.status >= 400, 'success': result.status >= 200 && result.status <= 300,
            'default': result.status == '' || result.status == null}">
            <div >
              <span><b>Method:</b>  {{ result.method }}</span> <br>
              <span><b>Request Data:</b> {{ result.reqData }}</span> <br>
              <span><b>Status:</b> {{ result.status }}</span> <br>
              <span><b>URL:</b> {{ result.url }}</span> <br>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="testStepResult?.addonTestData && testStepResult?.addonActionLogs">
        <div
          (click)="toggleAddonLogs()"
          class="fz-16 pt-2 mt-5 theme-text pointer">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isShowAddonLogs"
            [class.pr-7]="isShowAddonLogs"
            [class.fa-arrow-right-filled]="!isShowAddonLogs">
          </i>
          <span class="rb-medium" [translate]="'step_result.desired.addon_action_logs'" ></span>
        </div>
        <div *ngIf="isShowAddonLogs" class="mt-5 p-10 fz-15 addon_logs ">
          <span [innerHTML]="testStepResult?.parseAddonLogs" ></span>
        </div>
      </div>
      <div
        *ngIf="environmentResult?.testDeviceSettings?.capabilities.length">
        <div
          (click)="toggleCapabilities()"
          class="fz-16 pb-2 theme-text pointer">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isCapabilities"
            [class.pr-7]="isCapabilities"
            [class.fa-arrow-right-filled]="!isCapabilities">
          </i>
          <span class="rb-medium" [translate]="'step_result.desired.capabilities'"></span>
        </div>
        <ngx-json-viewer
          class="custom-json-view custom-stature"
          [json]="this.processJsonCapabilities()" *ngIf="isCapabilities">
        </ngx-json-viewer>
      </div>
      <div>
        <div *ngIf="canShowDetails()"
          (click)="toggleShowDetails()"
          class="fz-16 pb-2 theme-text pointer">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="showDetails"
            [class.pr-7]="showDetails"
            [class.fa-arrow-right-filled]="!showDetails">
          </i>
          <span class="rb-medium" [translate]="'step_result.app_activity'"></span>
        </div>
        <ngx-json-viewer
          class="custom-json-view custom-stature"
          [json]="this.appDetails" *ngIf="showDetails">
        </ngx-json-viewer>
      </div>
      <!-- <div class="pb-50"
        *ngIf="testStepResult && !(testStepResult.isStepGroup || testStepResult.isForLoop || testStepResult.isRestStep || testStepResult.stepGroup) || testStepResult?.template"
        id="attachment">
        <div
          (click)="toggleAttachment()"
          class="fz-16 theme-text pt-40 pb-2 pointer">
          <i
            class="fz-12"
            [class.fa-arrow-down-filled]="isShowAttachment"
            [class.pr-7]="isShowAttachment"
            [class.fa-arrow-right-filled]="!isShowAttachment">
          </i>
          <span class="rb-medium" [translate]="'message.common.attachments'"></span>
        </div>
        <div *ngIf="isShowAttachment">
          <app-result-attachment
            [attachmentRow]="'TESTCASE_STEP_RESULT'"
            [smallWidget]="true"
            [attachmentRowId]="testStepResult.id">
          </app-result-attachment>
        </div>
      </div> -->
      <div class="pt-30" >

      </div>
      <app-rest-step-result-details
        [metaData]="testStepResult?.metadata"
        *ngIf="testStepResult?.isRestStep && testStepResult?.metadata">
      </app-rest-step-result-details>
</div>
</div>
