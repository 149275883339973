<div class="page-header d-flex fz-20 align-items-center justify-content-between">
  <test-data-filter
    class="text-truncate rb-medium d-flex align-items-center"
    [filterByEnumList]="filteredByEnumList"
    [filterByValue]="filteredByValue"
    [translatePrefix]="'test_data_profiles.title.filter_by.'"
    (filterAction)="filter($event)">
  </test-data-filter>
  <div class="d-flex align-items-center ml-auto"
       *ngIf="selectedDataProfiles.length">
    <button (click)="openDeleteDialog(null)"
            [matTooltip]="'hint.message.common.delete_selected' | translate"
            class="btn icon-btn border-rds-2 ml-14"
            *ngIf="selectedDataProfiles.length">
      <i class="fa-trash-thin"></i>
    </button>
  </div>
  <div class="align-items-center"
       [class.d-none]="hideHeaderToolBar"
       [class.d-flex]="!hideHeaderToolBar">
    <app-toggle-search-button
      (searchAction)="fetchDataProfiles($event)"></app-toggle-search-button>
    <button
      [routerLink]="['new']"
      class="theme-btn-primary ml-14 "
      [translate]="'btn.common.create'">
    </button>
    <!-- <button
      (click)="importPopup()"
      [matTooltip]="'hint.message.common.import' | translate"
      class="btn icon-btn border-rds-2 ml-14">
      <i class="fa-download-thin"></i>
    </button> -->
    <app-sort-by-button
      class="pr-15" *ngIf="dataProfiles?.totalElements"
      [sortByColumns]="sortByColumns"
      [sortedBy]="sortedBy"
      [direction]="direction"
      [translatePreFix]="'test_data_profiles.sort_by.'"
      (sortAction)="sortBy($event.sortBy, $event.direction)"></app-sort-by-button>
    <div class="pl-15 theme-border-l">
      <app-pagination *ngIf="dataProfiles?.totalElements" [paginationData]="dataProfiles"
                      [currentPage]="currentPage" (paginationAction)="fetchDataProfiles()"></app-pagination>
    </div>
  </div>
</div>
<div class="page-content">
  <div
    *ngIf="dataProfiles?.content.length !== 0"
    class="list-header">
    <div
      class="pr-10 d-flex">
        <mat-checkbox
        [(ngModel)]="selectAll" [ngModelOptions]="{standalone: true}"
        (change)="selectAllToggle(selectAll)"
        [checked]="selectedDataProfiles.length === dataProfiles?.content?.length"
          class="mat-checkbox"></mat-checkbox>
      <span
        class="pl-15">
        <app-inline-sort
        [ascending]="sortedBy!=='testDataName'? undefined : direction === ',asc'"
        (click)="sortBy('testDataName', ((sortedBy!=='testDataName' || direction === ',desc')?',asc':',desc'))"
        [heading]="'message.common.text.title'"></app-inline-sort>
      </span>
    </div>
  </div>
  <app-placeholder-loader
    *ngIf="!fetchingCompleted"></app-placeholder-loader>
  <div class="list-container scrollable-container">
    <div class="list-view green-highlight lg-pm pl-10 pointer align-items-center"
         *ngFor="let dataProfile of dataProfiles?.content; let i = index">
      <a class="ts-col-85 my-auto text-truncate">
        <div class="pb-7 list-title d-flex">
        <mat-checkbox
            [(ngModel)]="dataProfile.isSelected" [ngModelOptions]="{standalone: true}"
            (change)="setSelectedList(dataProfile.id,dataProfile.isSelected)"
          class="mat-checkbox"></mat-checkbox>
          <span class="pl-15" [textContent]="dataProfile.name"
                [routerLink]="['/td', 'data', dataProfile.id]"></span>
        </div>
      </a>
      <div class="ts-col-15 text-center action-icons my-auto"
           *ngIf="!selectedDataProfiles || !selectedDataProfiles.length">
        <a
          data-placement="bottom"
          [matTooltip]="'pagination.edit' | translate"
          [routerLink]="['/td', 'data', dataProfile.id, 'edit']"
          class="fa-pencil-on-paper action-icon"></a>
        <a
          data-placement="bottom" (click)="fetchLinkedCases(dataProfile)"
          [matTooltip]="'pagination.delete' | translate"
          class="fa-trash-thin action-icon"></a>
      </div>
    </div>
  </div>
  <div *ngIf="!dataProfiles?.content.length" class="h-100">
    <div *ngIf="fetchingCompleted"
         class="empty-full-container">
      <div class="empty-run-md"></div>
      <div
        class="empty-text"
        [translate]="(filteredByValue !== 'all' || isFiltered) ? 'test_data_profiles.list_view.empty.filtered_list' : 'test_data_profiles.list_view.empty.list'"></div>
      <div class="d-flex" *ngIf="!isFiltered && filteredByValue === 'all'">
        <button [routerLink]="['new']" target="_parent"
           class="theme-btn-primary">
          +
          <span [translate]="'btn.common.create'"></span>
        </button>
        <a (click)="importPopup()"
           [matTooltip]="'hint.message.common.import' | translate"
           class="theme-btn-clear-default ml-14">
          <i class="fa-download-thin"></i>
          <span [translate]="'btn.common.import'"></span>
        </a>
      </div>
    </div>
  </div>
</div>

