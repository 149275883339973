import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { SessionService } from "../shared/services/session.service";
import { UserPreferenceService } from "app/services/user-preference.service";
import { MixpanelService } from '../mixpanel.service';  // Import the Mixpanel service

@Injectable({
  providedIn: "root",
})
export class LoginRedirectGuard implements CanActivate {
  constructor(
    private session: SessionService,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private mixpanelService: MixpanelService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.session.setToken(route.params["token"], true);
    let data = {
      jwt: localStorage.getItem("_t"),
      origin : window.location.hostname.split('.')[0],
      userEmail: localStorage.getItem('useremail')
    };
    chrome.runtime.sendMessage('pgadpooodaipbhbchnojijmlkhpamadh', { message: "getStatus", data: data })
    return this.userPreferenceService.show().pipe(
      switchMap((res: any) => {
        if (!res.clickedSkipForNow) {
          res.clickedSkipForNow = 1;
          this.mixpanelService.setUserProperties({"last_login": new Date().toISOString(), "is_first_time": true});
          this.mixpanelService.track('Login', {
            "is_first_time": true,
            "org_name": window.location.hostname.split('.')[0],
          });
            return this.userPreferenceService.save(res).pipe(
              map(() => this.router.parseUrl('/landing'))
          );
        } else {
          this.mixpanelService.setUserProperties({"last_login": new Date().toISOString(), "is_first_time": false});
          this.mixpanelService.track('Login', {
            "is_first_time": false,
            "org_name": window.location.hostname.split('.')[0],
          });
          return of(this.router.parseUrl('/dashboard'));
        }
      })
    );
  }
}