<div class="pl-60 pr-20 py-20 ts-col-100 h-100">
  <div class="py-6 ts-form d-flex align-items-baseline justify-content-between">
    <div class="position-relative w-25">
      <input
        #searchInput
        class="form-control border-0 ts-col-100"
        [placeholder]="'common.place_holder.search' | translate"
      />
      <i
        class="fa-search position-absolute"
        style="right: 12px; bottom: 8px"
      ></i>
    </div>
    <div>
      <button
        class="theme-btn-clear-default"
        [translate]="
          isMobile
            ? 'test_plan.environment.device.title'
            : ('test_plan.environment.machine.title' | translate)
        "
        (click)="openSelectTestMachineForm(testDevices?.cachedItems[0])"
      ></button>
    </div>
  </div>

  <div
    class="list-container theme-w-o-h-scroll lg-h"
    *ngIf="!testDevices.isEmpty"
  >
    <cdk-virtual-scroll-viewport
      class="virtual-scroll-viewport outer-sm-pm"
      itemSize="90"
    >
      <div
        class="list-view green-highlight lg-pm pl-10 pointer align-items-center"
        style="display: block; padding: 0 15px !important"
        *cdkVirtualFor="let environment of testDevices"
      >
        <ng-container>
          <div
            class="green-highlight lg-pm pl-10 pointer align-items-center"
            style="display: flex; padding: 15px"
          >
            <mat-slide-toggle
              [disabled]="!isEnvironmentAvailable && !environment.disable"
              [matTooltip]="
                !isEnvironmentAvailable && !environment.disable
                  ? ('environment.disable_info' | translate)
                  : ''
              "
              (change)="
                isEnvironmentAvailable || environment.disable
                  ? toggleMachineEnable(environment)
                  : ''
              "
              class="ts-col-5 z-in-2"
              [ngModel]="!environment.disable"
            ></mat-slide-toggle>
            <div class="pr-10 ts-col-50 text-decoration-none rb-medium">
              <span [textContent]="'#' + environment.id" class="pr-10"></span>
              <span [textContent]="environment.title"></span>
              <a
                *ngIf="isCustomPlan"
                (click)="expand(environment)"
                class="pl-20 text-decoration-none text-t-secondary"
                [textContent]="
                  (environment?.testSuites?.length
                    ? environment?.testSuites?.length
                    : '') +
                  ' ' +
                  ('test_plans.details.suites' | translate)
                "
              ></a>
            </div>
            <div class="ts-col-30 text-t-secondary d-flex align-items-center">
              <app-test-machine-info-column
                *ngIf="testPlan"
                [testPlan]="testPlan"
                [testDevice]="environment"
              ></app-test-machine-info-column>
            </div>
            <div
              class="ts-col-20 action-icons text-right position-absolute right-10 mr-60 mt-10"
            >
              <a
                class="action-icon"
                data-placement="bottom"
                (click)="openSelectTestMachineForm(environment, true)"
                [matTooltip]="'pagination.edit' | translate"
              >
                <i class="fa-pencil-on-paper"></i>
              </a>
              <a
                class="action-icon"
                *ngIf="testDevices.totalElements > 1"
                (click)="deleteEnvironment(environment)"
                data-placement="bottom"
                [matTooltip]="'pagination.delete' | translate"
              >
                <i class="fa-trash-thin"></i>
              </a>
            </div>
          </div>
          <div
            class="text-center overflow-x-hidden"
            *ngIf="
              environment.testSuites && activeEnvironment?.id == environment.id
            "
          >
            <!-- <ng-container *ngFor="let suite of environment.testSuites; let first= first; let last = last">
                  <span [textContent]="suite.name"></span>
                
                </ng-container> -->
            <!-- Iterate over each test suite -->

            <!-- Loop through each suite -->
            <div *ngFor="let suite of environment.testSuites; let i = index" class="suite-item">
              <!-- Parent Suite -->
              <div class="suite-header" (click)="toggleSuite(suite.id)">
                <div class="icon-container">
                  <mat-icon class="arrow-icon" [class.expanded]="isSuiteExpanded(suite.id)">
                    chevron_right
                  </mat-icon>
                </div>
                <div class="icon-container">
                  <mat-icon>
                    {{ isSuiteExpanded(suite.id) ? 'folder_open' : 'folder' }}
                  </mat-icon>
                </div>
                <div class="result-status-8 list-number">{{ i + 1 }}</div>
                <span>{{ suite.name }}</span>
              </div>

              <!-- Suite Content -->
              <div class="suite-content" *ngIf="isSuiteExpanded(suite.id)">
                <!-- Parent Suite Test Cases -->
                <div *ngIf="suite.testCasesDto?.length">
                  <div *ngFor="let testCase of suite.testCasesDto; let index = index"
                       class="test-case-item"
                       [routerLink]="['/td', 'cases', testCase.id]">
                    <div class="icon-container">
                      <mat-icon>description</mat-icon>
                    </div>
                    <div class="result-status-8 list-number">{{ index + 1 }}</div>
                    <span>{{ testCase?.name }}</span>
                    
                    <div class="ml-auto d-flex align-items-center">
                      <div *ngIf="testCase?.lastRun" class="mr-3">
                        <app-result-pie-chart-column
                          [height]="24"
                          [resultEntity]="testCase?.lastRun"
                          [width]="24">
                        </app-result-pie-chart-column>
                      </div>
                      <span class="text-muted">
                        <!-- {{ "testcase.status_" + testCase?.status | translate }} -->
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Child Suites -->
                <div *ngIf="suite.childSuites?.length">
                  <div *ngFor="let childSuite of suite.childSuites" class="suite-item">
                    <div class="suite-header" (click)="toggleSuite(childSuite.id)">
                      <div class="icon-container">
                        <mat-icon class="arrow-icon" [class.expanded]="isSuiteExpanded(childSuite.id)">
                          chevron_right
                        </mat-icon>
                      </div>
                      <div class="icon-container">
                        <mat-icon>
                          {{ isSuiteExpanded(childSuite.id) ? 'folder_open' : 'folder' }}
                        </mat-icon>
                      </div>
                      <span>{{ childSuite.name }}</span>
                    </div>

                    <!-- Child Suite Test Cases -->
                    <div class="suite-content" *ngIf="isSuiteExpanded(childSuite.id)">
                      <div *ngFor="let testCase of childSuite.testCasesDto; let j = index"
                           class="test-case-item"
                           [routerLink]="['/td', 'cases', testCase.id]">
                        <div class="icon-container">
                          <mat-icon>description</mat-icon>
                        </div>
                        <div class="result-status-8 list-number">{{ j + 1 }}</div>
                        <span>{{ testCase?.name }}</span>

                        <div class="ml-auto d-flex align-items-center">
                          <div *ngIf="testCase?.lastRun" class="mr-3">
                            <app-result-pie-chart-column
                              [height]="24"
                              [resultEntity]="testCase?.lastRun"
                              [width]="24">
                            </app-result-pie-chart-column>
                          </div>
                          <span class="text-muted">
                            <!-- {{ "testcase.status_" + testCase?.status | translate }} -->
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <app-placeholder-loader
        *ngIf="testDevices.isFetching"
      ></app-placeholder-loader>
    </cdk-virtual-scroll-viewport>
  </div>
  <div *ngIf="testDevices.isEmpty" class="h-100">
    <div class="empty-full-container">
      <div class="empty-run-md"></div>
      <div
        class="empty-text"
        [translate]="'message.common.search.not_found'"
      ></div>
    </div>
  </div>
</div>
