import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScreenShortOverlayComponent } from '../screen-short-overlay.component';
import { TestStep } from 'app/models/test-step.model';
import { TestStepPriority } from 'app/enums/test-step-priority.enum';
import { TestStepType } from 'app/enums/test-step-type.enum';
import { BaseComponent } from 'app/shared/components/base.component';
import { TestStepService } from 'app/services/test-step.service';
import { VideoAnalysisService } from 'app/components/results/run-result-page/video-analysis.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ToastrService } from 'ngx-toastr';
// import { BaseComponent } from "../../shared/components/base.component";
@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent extends BaseComponent implements OnInit {
  testCaseSteps: any[] = [];
  runData: any;
  filteredSteps: any[] = [];
  totalStep: any[] = [];
  failedSteps :any[] = [];
  test_steps_executed :any[] = [];
  payload :any ;
  public templates:any[] = [];
  public testStep: TestStep
  public testCaseId :any
  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SuggestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matModal: MatDialog,
    private testStepService: TestStepService,
    public videoanalysis: VideoAnalysisService,
    public toastrService: ToastrService,
    public notificationsService: NotificationsService,
  ) {
    super();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.testCaseSteps = this.data.testCaseSteps || [];
    // this.testCaseSteps =  [];

    this.runData = this.data.runData;
    this.totalStep =  this.data.totalStep;
    this.failedSteps = this.data.failedSteps;
    this.templates = this.data.templates;
    this.testCaseId = this.data.testCaseId;
    this.test_steps_executed = this.data.test_steps_executed;
    this.payload = this.data.payload;
    this.filteredSteps = this.testCaseSteps
      .map((step, index) => ({ ...step, originalIndex: index }))
      .filter((_, index) => index === 0);
    // this.showmessage();
  }

  addorupdateData(stepdata: any) {
    let selectedTemplate = this.templates.filter((data)=> data.displayName == stepdata.Action)[0];
    // console.log(selectedTemplate, "displayName");
    if(stepdata.Type == 'Create'){
      this.createstep(selectedTemplate,Number(stepdata.Step - 1),this.testCaseId, stepdata);
    }else if(stepdata.Type == 'Update'){
      this.updateStep(selectedTemplate,Number(stepdata.Step - 1),this.testCaseId, stepdata);
    }
  }

  addorupdateallData(){
    this.testCaseSteps.map((data)=>{
      this.addorupdateData(data);
    })
  }

  async rehectall(){
    this.loading = true;
    this.testCaseSteps = [];
    let res = await this.videoanalysis.aisuggestion(this.payload).toPromise();
    this.testCaseSteps = res.response.steps;
    this.loading = false;
  }

  suggestions: string[] = [
    "Add verify template",
    "Add well-defined prompt",
    "Convert to parameterize",
    "Remove unwanted template",
    "AI Analysis",
    "Check for vulnerabilities or security threats",
  ];

  testCaseItems: string[] = ["Steps", "Images", "Metadata", "Assertions"];

  comparisonView: 'split' | 'unified' = 'split';

  oldVersion: string = `- Step 1: Navigate to login page
- Step 2: Enter username
- Step 3: Enter password
- Step 4: Click login button`;

  newVersion: string = `+ Step 1: Navigate to login page
+ Step 2: Enter username
+ Step 3: Enter password
+ Step 4: Click login button
+ Step 5: Verify successful login`;

  unifiedDiff: string = `  Step 1: Navigate to login page
  Step 2: Enter username
  Step 3: Enter password
  Step 4: Click login button
+ Step 5: Verify successful login`;

  applySuggestion(suggestion: string) {
    console.log(`Applying suggestion: ${suggestion}`);
    // Implement the logic to apply the suggestion
  }

  approveAll() {
    console.log('Approving all changes');
    // Implement the logic to approve all changes
  }

  denyAll() {
    console.log('Denying all changes');
    // Implement the logic to deny all changes
  }

  approveItem(item: string) {
    console.log(`Approving ${item}`);
    // Implement the logic to approve the specific item
  }

  createstep(selectedTemplate,position: number, testCaseId, newData){
      let testStep = new TestStep();
      testStep.position = position;
      testStep.conditionIf = [];
      testStep.testCaseId = testCaseId;
      testStep.waitTime = 30;
      testStep.priority = TestStepPriority.MAJOR;
      testStep.type = TestStepType.ACTION_TEXT;
      // testStep.conditionType = TestStepConditionType.CONDITION_IF;
      testStep.template = selectedTemplate;
      testStep.metadata = newData.metadata;
      testStep.action = newData.newStep;
      testStep.naturalTextActionId = selectedTemplate.id;
      this.testStep = testStep;
      this.syncEventData(this.testStep);
      this.testStep.actionName = this.testStep.event.customEvent as string;
      console.log(this.testStep);
      this.testStepService.create(this.testStep).subscribe(
        (step) => {
          // this.afterSave(step)
          // const action = step.action;
          // const actionText = action.replace(/<[^>]*>/g, '');
          // this.mixpanelService.track('Test Case Step Created', {
          //   "title": actionText,
          //   "id": step.id
          // });
          this.showNotification(NotificationType.Success, 'Step created successfully');
        },
        (e) => this.handleSaveFailure(e)
      );
      // if (this.version.workspace.isRest) testStep.type = TestStepType.REST_STEP;
      
  }

  updateStep(selectedTemplate, position: number, testCaseId, newData) {
    // Ensure updatedStep is an instance of TestStep
    let updatedStep: TestStep = Object.assign(new TestStep(), this.test_steps_executed[newData.Step - 1]);
    updatedStep.metadata = newData.metadata;
    updatedStep.action = newData.newStep;
    this.testStepService.update(updatedStep).subscribe(
      (step) => {
        // this.afterSave(step)
        // const action = step.action;
        // const actionText = action.replace(/<[^>]*>/g, '');
        // this.mixpanelService.track('Test Case Step Created', {
        //   "title": actionText,
        //   "id": step.id
        // });
        this.showNotification(NotificationType.Success, 'Step updated successfully');
      },
      (e) => this.handleSaveFailure(e)
    );
  }

  syncEventData(step) {
    // console.warn(this.testStep.eventMap.entries());
    const { template } = step;
    const tempEvent = step.event
    const event = {
      customEvent: template?.displayName,
    };
    const vars = template.variables as Record<
      string,
      Record<"path" | "default", string>
    >;
    this.testStep.event = JSON.parse(JSON.stringify(event));
    if (vars) {
      Object.entries(vars).forEach(([placeholder, config]) => {
        /** Event Key */
        const key = config.path.replace("event.", "");
        event[key] = this.testStep.eventMap.get(key) ?? config.default;
      });

      // step.event = JSON.parse(JSON.stringify(event));
      step.event = JSON.parse(JSON.stringify(event));


    }
    // step.event = JSON.parse(JSON.stringify(event));
    step.event = JSON.parse(JSON.stringify(event));
    if (step.event.isHtmlRequired) {
      step.event.isHtmlRequired = tempEvent.isHtmlRequired;
    }
    if (step.event.isImageRequired) {
      step.event.isImageRequired = tempEvent.isImageRequired;
    }
    let PwlocatorData = this.testStep?.eventMap.get("pwLocator");
    if (PwlocatorData) {
      step.event.pwLocator = PwlocatorData;
    } else if (step.event.selector) {
      step.event.pwLocator = [step.event.selector];
    } else {
      step.event.pwLocator = undefined;
    }
    if (this.testStep.testDataType === "environment" && this.testStep.event.customEvent === "downloadExtension") {
      this.testStep.event.extension_url = `*|${this.testStep.testDataVal}|`
    }
    // if(step.event && step.event.pwLocator && step.event.pwLocator.length){
    //     step.event.pwLocator = PwlocatorData
    // }else {
    //   if(step?.event?.selector) {
    //       step.event.pwLocator = this.testStep ?.eventMap ?.get('pwLocator')
    //     }
    //   }
  }


  denyItem(item: string) {
    console.log(`Denying ${item}`);
    // Implement the logic to deny the specific item
  }
  private handleSaveFailure(err, isUpdate?) {
    let msgKey = Boolean(isUpdate)
      ? "message.common.update.failure"
      : "message.common.created.failure";
    this.showAPIError(
      err,
      this.translate.instant(msgKey, { FieldName: "Test Step" })
    );
    // this.saving = false;
  }

  setComparisonView(view: 'split' | 'unified') {
    this.comparisonView = view;
  }

  openScreenShort(url) {
    this.matModal.open(ScreenShortOverlayComponent, {
      data: { screenShortUrl: url },
    });
  }
// showmessage(){
//   this.translate.get("execution.initiate.success").subscribe((res: string) => {
//     debugger
//     this.showNotification(NotificationType.Success, res);
//   })
// }
}
