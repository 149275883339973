import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestPlanResultService } from "../../services/test-plan-result.service";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from 'moment';
import { Page } from "../../shared/models/page";
import { TestDeviceResult } from "../../models/test-device-result.model";
import { TestPlanResult } from "../../models/test-plan-result.model";
import { TestDeviceResultService } from "../../shared/services/test-device-result.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TestCaseResultService } from "../../services/test-case-result.service";
import { TestCaseResult } from "../../models/test-case-result.model";
import { OnBoardingSharedService } from "../../services/on-boarding-shared.service";
import { UserPreferenceService } from "../../services/user-preference.service";
import { LicenceSevices } from "../../shared/services/license.service";
import { async, interval, of, Subscription } from "rxjs";
import { TestCaseService } from "../../services/test-case.service";
import { TestCase } from "../../models/test-case.model";
import { TestStepService } from "../../services/test-step.service";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RunHistoryDetailsComponent } from '../results/run-history-details/run-history-details.component';
import { TestStepResultService } from 'app/services/test-step-result.service';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';
import { errorFixTypepopupComponent } from './errorFix-Type-popup.component';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BaseComponent } from 'app/shared/components/base.component';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { TranslateService } from '@ngx-translate/core';
import { SuggestionComponent } from './suggestion/suggestion.component';
import { VideoAnalysisService } from '../results/run-result-page/video-analysis.service';
import { NaturalTextActionsService } from 'app/services/natural-text-actions.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';
import { TestStepType } from 'app/enums/test-step-type.enum';
import { WorkspaceType } from 'app/enums/workspace-type.enum';
import { NaturalTextActions } from 'app/models/natural-text-actions.model';

@Component({
  selector: 'app-runs-history',
  templateUrl: './runs-history.component.html',
  styles: [`
    .button {
      display: inline-flex;
      align-items: center;
      padding: 7px 9px; /* Add padding for a better button size */
      background-color: #6366f1; /* Button background color */
      color: white; /* Text color */
      border-radius: 4px; /* Rounded corners */
      cursor: pointer; /* Pointer cursor on hover */
      transition: background-color 0.3s ease; /* Smooth background color transition */
      text-decoration: none; /* Remove underline */
    }

    .button:hover {
      background-color: #4f46e5; /* Darker shade on hover */
    }

    .spinner {
      margin-right: 8px; /* Space between spinner and text */
    }
  `]
})
export class RunsHistoryComponent extends BaseComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  @Output() nullResult = new EventEmitter<void>();
  @Output() fixedData = new EventEmitter<void>();
  private hasEmittedNullResult: boolean = false; // Add a flag
  @Input() lastRun: any;
  @Input() lastRunId: any;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  public dryTestCaseResults: InfiniteScrollableDataSource;
  public testPlanResults: InfiniteScrollableDataSource;
  public dayResults: InfiniteScrollableDataSource;
  public templates: Page<NaturalTextActions>;
  public weekResults: InfiniteScrollableDataSource;
  public monthResults: InfiniteScrollableDataSource;
  public quarterResults: InfiniteScrollableDataSource;
  public filterTimePeriod = FilterTimePeriod;
  public environmentResults: Page<TestDeviceResult>;
  public activeExecutionResult: TestPlanResult;
  public isRunning: boolean;
  public autoRefreshSubscription: Subscription;
  public isDisabledAutoRefresh: boolean = false;
  public autoRefreshInterval: number = 10000;
  public testCaseId: number;
  public lastFailedStepIndex: number;
  public testCase: TestCase;
  noSteps: any;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  private runhistoryModal !: any;
  test_steps_executed!: any[];
  testStepDataRunResult: any;
  traceViewer: any;
  testCaseStepsSugggestion: any;
  cqaOrigin: any;
  public currentStepType: string;
  public isLoading: boolean = false; // Add loading state

  constructor(
    public matModal: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private environmentResultService: TestDeviceResultService,
    private testPlanResultService: TestPlanResultService,
    private testCaseResultService: TestCaseResultService,
    private userPreferenceService: UserPreferenceService,
    private versionService: WorkspaceVersionService,
    private naturalTextActionsService: NaturalTextActionsService,
    public LicenceSevices: LicenceSevices,
    private testStepService: TestStepService,
    private testCaseService: TestCaseService,
    private onBoardingSharedService: OnBoardingSharedService,
    private dialog: MatDialog,
    private testStepResultService: TestStepResultService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public videoanalysis : VideoAnalysisService,
    public toastrService: ToastrService,
    private sanitizer: DomSanitizer) {
    super(authGuard, notificationsService, translate, toastrService)

  }

  async ngOnInit(): Promise<any> {
    this.testCaseId = this.lastRunId || this.route.parent.snapshot.params.testCaseId;
    this.fetchTestCaseResults();
    this.fetchTestCase();
    this.attachAutoRefreshEvents();
    this.fetchTestSteps();
    this.fetchNLActions();
    this.cqaOrigin = window.location.hostname.split(".")[0];
  }

  fetchTestSteps() {
    let query = "testCaseId:" + this.testCaseId;
    this.testStepService.findAll(query).subscribe(res => {
      this.testCaseStepsSugggestion = res.content;
      this.noSteps = res.empty;
      this.testCaseService.emitStepLength(res?.content?.length);
    });
  }

  fetchTestCase() {
    this.testCaseService.show(this.testCaseId).subscribe(res => {
      this.testCase = res;
    })
  }

  attachAutoRefreshEvents() {
    document.addEventListener("visibilitychange", () => {
      document.hidden ? this.removeAutoRefresh() : this.addAutoRefresh(true);
    });
  }

  fetchVersion() {
    this.versionService.show(this.testCase.workspaceVersionId).subscribe(res => {
      this.version = res;
      this.currentStepType = this.currentStepType ? this.currentStepType :
        this.version.workspace.isRest ? TestStepType.REST_STEP : TestStepType.ACTION_TEXT;
      this.fetchNLActions();
    })
  }

  fetchNLActions(subQuery?) {
    let workspaceType: WorkspaceType = this.version.workspace.workspaceType;
    this.naturalTextActionsService.findAll("workspaceType:" + workspaceType + (subQuery ? subQuery : '')).subscribe(res => {
      this.templates = res;
      console.log(this.templates);
    });
  }

  async fetchTestCaseResults() {
    this.isRunning = false;
    this.removeAutoRefresh();
    this.dryTestCaseResults = new InfiniteScrollableDataSource(this.testCaseResultService, "entityType:TEST_PLAN,iteration:null,testCaseId:" + this.testCaseId, "id,desc");
    this.testCaseResultService.findAll("entityType:TEST_PLAN,iteration:null,testCaseId:" + this.testCaseId, "id,desc").subscribe(async res => {
      this.lastFailedStepIndex = await this.findlastfailedStepIndex(res.content);
      if (res.content.length === 0 && !this.hasEmittedNullResult) {
        this.nullResult.emit();
        this.hasEmittedNullResult = true; // Set the flag to true
        return;
      }
      res.content.filter(dryexecution => {
        if (dryexecution && dryexecution.isQueued) {
          this.isRunning = true;
          this.addAutoRefresh();
          return;
        }
      })
    })
    console.log(this.dryTestCaseResults)
  }

  async findlastfailedStepIndex(testResults: any[]): Promise<number> {

    if (!testResults?.length) return null;

    // Find the last failed result by iterating through results in reverse
    for (let i = 0; i < testResults.length; i++) {
      const result = testResults[i];
      if (result?.result === 'FAILURE') {
        return i;
      }
    }
    return null;
  }

  addAutoRefresh(listenerChangeTrue?: boolean) {
    if (listenerChangeTrue && this.isRunning && !this.isDisabledAutoRefresh) {
      this.fetchTestCaseResults();
    }
    this.removeAutoRefresh();
    if (!this.isRunning || this.isDisabledAutoRefresh)
      return;
    this.autoRefreshSubscription = interval(this.autoRefreshInterval).subscribe(() => {
      this.fetchTestCaseResults();
    });
  }


  removeAutoRefresh() {
    if (this.autoRefreshSubscription) {
      this.isRunning = false;
      this.autoRefreshSubscription.unsubscribe();
    }
  }


  filter(period: FilterTimePeriod) {
    this.period = period;
    const date = this.getFormattedDate(period);
    this.userPreferenceService.setDaysFilter({ period: this.period, date: date })
    switch (this.period) {
      case FilterTimePeriod.TODAY:
        this.testPlanResults = this.dayResults;
        break;
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        this.testPlanResults = this.weekResults;
        break;
      case FilterTimePeriod.LAST_30_DAYS:
        this.testPlanResults = this.monthResults;
        break;
      case FilterTimePeriod.LAST_90_DAYS:
        this.testPlanResults = this.quarterResults;
        break;
    }
    this.setFirstActiveResult();
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
    }
  }

  private setFirstActiveResult(): void {
    if (this.testPlanResults.isFetching)
      setTimeout(() => this.setFirstActiveResult(), 500);
    else
      if (this.testPlanResults.isEmpty) this.environmentResults = null;
    this.activeExecutionResult = <TestPlanResult>this.testPlanResults['cachedItems'][0];
    if (this.activeExecutionResult)
      this.fetchEnvironmentResults();
  }

  private fetchEnvironmentResults(): void {
    this.environmentResults = null;
    this.environmentResultService.findAll("testPlanResultId:" + this.activeExecutionResult.id).subscribe(res => {
      this.environmentResults = res;
    });
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });

  }

  public setActiveResult(result: TestPlanResult) {
    this.activeExecutionResult = result;
    this.fetchEnvironmentResults();
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });
    // this.router.navigate(['/td','runs',result.id])
  }
  async openQuickFix(testStepData) {
    const stepId = testStepData.type === 'TS_TIMEOUT' ? testStepData.timeoutStep : testStepData.failed_step_id;
    // First, retrieve the matching step data
    const dialogRef = await this.testStepService.show(stepId).pipe(
      switchMap(matchingStep => {
        // Once the matching step is retrieved, open the modal dialog with the necessary data
        return of(this.matModal.open(errorFixTypepopupComponent, {
          height: '30%',
          width: '30%',
          data: { testStepData, matchingStepData: matchingStep },
          panelClass: ['mat-dialog', 'rds-none']
        }).afterClosed());
      })
    ).toPromise();

    // Handle dialog close response
    dialogRef.subscribe((data) => {
      if (data && data != "Cancel") {
        this.showNotification(NotificationType.Success, 'Value updated successfully');
        this.fixedData.emit();
      }
    });
  }
  /** Run History Detail Modal */
  runResultsHistory(data: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/run-history-view'], {
        queryParams: {
          testCaseId: this.testCaseId,
          runResultId: data.id,
          isManualExecution: data.isManualExecution
        }
      })
    );
    window.open(url, '_blank');

    // this.openModalInstant();
    // this.fetchTestCaseResult(data.id);
    // let query;
    // query = query ? query + "," : "";
    // query += "groupResultId:null,testCaseResultId:" + data.id;
    // this.testStepResultService.findAll(query, data.isManualExecution ? "id,asc" : "id,asc").subscribe(res => {
    //   if (res.content.length) {
    //     let testStepIds = res.content.map((data: any) => data.stepId);
    //     this.fetchTestStepsData(testStepIds, res.pageable, res.content);
    //   } else {
    //     this.test_steps_executed = [];
    //   }
    // });
  }

  // fetchTestStepsData(testStepIds, pageable, teststepResult) {
  //   let queryString = "id@" + testStepIds.join("#")
  //   this.testStepService.findAll(queryString, 'position', pageable).subscribe(res => {
  //     this.test_steps_executed = res.content.map((data: any) => {
  //       return {
  //         ...data,
  //         teststepResult: teststepResult.find((res) => res.stepId == data.id)
  //       };
  //     });
  //     this.runhistoryModal.componentInstance.TestSteps = this.test_steps_executed;
  //     this.runhistoryModal.componentInstance.testCaseData = this.testCase;
  //     this.runhistoryModal.componentInstance.testStepDataRunResult = this.testStepDataRunResult;
  //     this.runhistoryModal.componentInstance.traceViewer = this.traceViewer;
  //     this.runhistoryModal.componentInstance.loader = false;
  //   });
  // }


  /** Open Modal When clock on Run */
  // openModalInstant() {
  //   let config = new MatDialogConfig();
  //   config = {
  //     width: '100vw',
  //     maxWidth: '100%',
  //     height: '100%',
  //     panelClass: 'full-screen-modal',
  //     hasBackdrop: false
  //   };
  //   this.runhistoryModal = this.dialog.open(RunHistoryDetailsComponent, config);
  //   this.runhistoryModal.componentInstance.loader = true;
  // }

  // fetchTestCaseResult(id: any) {
  //   this.testCaseResultService.show(id).subscribe(res => {
  //     this.testStepDataRunResult = res;
  //     if (this.testStepDataRunResult?.traceViewers && this.testStepDataRunResult?.traceViewers != null) {
  //       this.traceViewer = this.testStepDataRunResult?.traceViewers.map(item => item ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${item}`) : null);
  //     }
  //   });
  // }
  async testStepResult(runResultId) {
    let query = `groupResultId:null,testCaseResultId:${runResultId}`;
    const res = await this.testStepResultService.findAll(query, "id,asc").toPromise(); // Convert to promise
    if (res.content.length) {
      let testStepIds = res.content.map((data: any) => data.stepId);
      await this.fetchTestStepsData(testStepIds, res.pageable, res.content);
    } else {
      this.test_steps_executed = [];
    }
  }

  /** Fetch Test Case Result Data */
  async fetchTestStepsData(testStepIds, pageable, teststepResult) {
    let queryString = "id@" + testStepIds.join("#");
    const res = await this.testStepService.findAll(queryString, 'position', pageable).toPromise(); // Convert to promise
    this.test_steps_executed = res.content.map((data: any) => {
      return {
        ...data,
        teststepResult: teststepResult.find((res) => res.stepId == data.id)
      };
    });
  }
  async openDialogSuggestion(runData: any, index: any) {
    this.isLoading = true; // Start loading
    // Arrays to hold the success and failure steps
    await this.testStepResult(runData.id);
    let totalSteps: any[] = [];
    const failedSteps: { [key: number]: any } = {}; // Declare an object to hold failed steps by stepNumber

    // Process each step in test_steps_executed
    this.test_steps_executed.forEach((step: any , index) => {
      totalSteps.push({
        description: step.description,
        action: step.action,
        metadata: step.metadata,
        screenshot: step.goldenScreenshot,
        stepNumber : index + 1,
        stepId : step.id,
        status: step.teststepResult?.result === 'SUCCESS' ? true : false
      });
      if(step.teststepResult?.result === "FAILURE" ){
        failedSteps[0] = { // Use step.position as the key
            description: step.description,
            action: step.action,
            stepNumber: step.position,
            metadata: step.metadata,
            screenshot: step.goldenScreenshot,
        };
      }
    });

    // Create the JSON payload
    const payload = {
      description: runData?.testCase.description,
      url: runData.screenRecordings[0],
      title: runData?.testCase.name,
      steps: totalSteps
    };

    // Open dialog after aisuggestion call
  //   const res = {
  //     "response": {
  //         "steps": [
  //             {
  //                 "Action": "waitUntilElementHasValue",
  //                 "Description": "Ensure that the 'Show less options' section is fully expanded before attempting to click on 'Fixed Price'.",
  //                 "Reason": "The 'Fixed Price' option might not be visible because 'Show less options' was not expanded properly.",
  //                 "Step": "13",
  //                 "Type": "Create",
  //                 "metadata": "Wait for the 'Show less options' section to be fully expanded.",
  //                 "newStep": "Wait until 'Show less options' is expanded"
  //             }
  //         ]
  //     },
  //     "success": true
  // }
    const res = await this.videoanalysis.aisuggestion(payload).toPromise(); // Convert to promise
    runData.index = index;
    const dialogRef = this.matModal.open(SuggestionComponent, {
      data: {
        testCaseSteps: res.response.steps,
        totalStep : totalSteps,
        runData: runData,
        testCaseId :this.testCaseId,
        templates : this.templates.content,
        failedSteps : failedSteps,
        test_steps_executed : this.test_steps_executed,
        payload :payload
      },
      disableClose: true,
      height: "100%",
      width: "60%",
      position: { top: "0px", right: "0px" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isLoading = false; // Stop loading when dialog closes
      console.log("Dialog closed");
    });
  }
}
